import { Model } from "@vuex-orm/core";

import { AxiosAbortError } from "@/helpers/ApiRequest.js";

/*
BaseModel is the default class that all our VuexOrm model extends from.
It provides a default state and all the default API methods.

Special note for the `requestWrap` method:
  It was developed for the `Suivi Volontaire` module.
  This method wraps any API call and handle automatically:
    - the loading state (via the fetchingMethod variable)
    - the error state (via the errorMessages variable)
  The main goal was to avoid code redundancy.
*/
export default class BaseModel extends Model {
  static resourceUrl = "";

  static state() {
    return {
      allLoaded: false,
      fetchingMethod: null,
      errorMessages: {},
    };
  }

  static getState() {
    return this.store().state.entities[this.entity];
  }

  static setFetchingMethod(value) {
    this.commit((state) => {
      state.fetchingMethod = value;
    });
  }

  static resetErrorMessages() {
    this.commit((state) => {
      state.errorMessages = {};
    });
  }

  static apiConfig = {
    actions: {
      /*Overrides default actions to set a fetchingMethod flag*/
      async get(url, config) {
        this.model.setFetchingMethod("get");
        try {
          return await this.request({ method: "get", url, ...config });
        } finally {
          this.model.setFetchingMethod(false);
        }
      },
      async post(url, data, config) {
        this.model.setFetchingMethod("post");

        try {
          return await this.request({ method: "post", url, data, ...config });
        } finally {
          this.model.setFetchingMethod(false);
        }
      },
      async put(url, data, config) {
        this.model.setFetchingMethod("put");

        try {
          return await this.request({ method: "put", url, data, ...config });
        } finally {
          this.model.setFetchingMethod(false);
        }
      },
      async patch(url, data, config) {
        this.model.setFetchingMethod("patch");

        try {
          return await this.request({ method: "patch", url, data, ...config });
        } finally {
          this.model.setFetchingMethod(false);
        }
      },

      async requestWrap(options) {
        const { method, url, payload, config } = { method: "get", url: this.model.resourceUrl, ...options };
        try {
          let response;
          if (method === "get" || method === "delete") {
            response = await this[method](url, { handlingErrors: false, ...config });
          } else {
            response = await this[method](url, payload, { handlingErrors: false, ...config });
          }
          this.model.resetErrorMessages();
          return response;
        } catch (error) {
          if (error instanceof AxiosAbortError) {
            throw error;
          }

          const globalError = {};
          if (error.response?.status === 500 || error.response?.status === 404) {
            globalError.GLOBAL = error.message;
          }
          this.model.commit((state) => {
            state.errorMessages = { ...state.errorMessages, ...globalError, ...error.response?.data?.validation_errors };
          });

          throw new Error(`${this.model.entity}.${method} failed`);
        }
      },
      /***/
      load(params, vuexOrmOptions) {
        return this.get(this.model.resourceUrl, { params, ...(vuexOrmOptions ?? {}) });
      },
      async loadById(id, params) {
        return await this.get(`${this.model.resourceUrl}${id}/`, { params });
      },
      async loadAll(params, options = { forceFetch: false }) {
        const currentState = this.model.store().state.entities[this.model.entity];
        if (!options.forceFetch && currentState.allLoaded) {
          return Promise.resolve(this.model.all());
        }

        const response = await this.get(this.model.resourceUrl, { params });
        this.model.commit((state) => {
          state.allLoaded = true;
        });

        return response.entities[this.model.entity];
      },
      async loadWithResults(params, vuexOrmOptions) {
        const response = await this.load(params, { dataKey: "results", ...(vuexOrmOptions ?? {}) });
        return [response.entities[this.model.entity] || [], response.response.data.count];
      },
      save(instances, params = {}, config = null) {
        if (!Array.isArray(instances)) {
          if (!instances.id || instances.id.startsWith("$")) {
            return this.post(`${this.model.resourceUrl}${params.query || ""}`, instances.$toJson(), config);
          } else {
            return this.put(`${this.model.resourceUrl}${instances.id}/${params.query || ""}`, instances.$toJson(), config);
          }
        }

        let instanceToCreate = [];
        let instanceToUpdate = [];

        for (let instance of instances) {
          if (instance.id.startsWith("$")) {
            instanceToCreate.push(instance.$toJson());
          } else {
            instanceToUpdate.push(instance.$toJson());
          }
        }

        let promises = [];
        if (instanceToCreate.length > 0) {
          promises.push(this.post(this.model.resourceUrl, instanceToCreate, config));
        }

        if (instanceToUpdate.length > 0) {
          promises.push(this.put(this.model.resourceUrl, instanceToUpdate, config));
        }

        return Promise.all(promises);
      },
      destroy(instances, config) {
        if (!Array.isArray(instances)) {
          return this.delete(`${this.model.resourceUrl}${instances.id}/`, {
            ...config,
            delete: instances.id,
          });
        }

        let jsonInstances = instances.map((instance) => instance.$toJson());
        return this.delete(`${this.model.resourceUrl}`, {
          ...config,
          save: false,
          data: jsonInstances,
        }).then(() => {
          for (let instance of instances) {
            this.model.delete(instance.id);
          }
        });
      },
    },
  };
}
